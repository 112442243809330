define(['jquery', 'app'], function($, app) {
  
  let cCarousel = {
    sConfig: window.requirejs.s.contexts._.config.config.siteSliderDefaults,
    sliderDefaults: {
      sliderSet: {
        addActiveClass: true,
        allowCSS3: false,
        arrowsNavAutoHide: false,
        arrowsNavHideOnTouch: true,
        autoHeight: true,
        autoScaleSlider: false,
        fadeinLoadedSlide: false,
        imageScaleMode: 'fill',
        keyboardNavEnabled: true,
        loop: true,
        minSlideOffset: 0,
        navigateByClick: false,
        numImagesToPreload: 6,
        slidesSpacing: 0,
        transitionType: 'move',
        autoPlay: {
          enabled: true,
          delay: 10000
        }
      },
      carouselSet: {
        addActiveClass: true,
        allowCSS3: false,
        arrowsNav: false,
        arrowsNavAutoHide: false,
        arrowsNavHideOnTouch: true,
        autoHeight: true,
        controlNavigation: 'none',
        controlsInside: false,
        imageScaleMode: 'fill',
        loop: true,
        slidesSpacing: 3
      },
      productImageSlider: {
        addActiveClass: true,
        arrowsNav: false,
        arrowsNavHideOnTouch: true,
        autoHeight: true,
        controlNavigation: 'none',
        imageScaleMode: 'fill',
        keyboardNavEnabled: true,
        loop: true
      },
      productItemSlider: {
        allowCSS3: false,
        arrowsNav: true,
        arrowsNavAutoHide: false,
        arrowsNavHideOnTouch: true,
        autoHeight: true,
        controlNavigation: 'none',
        controlsInside: false,
        loop: true,
        slidesSpacing: 0,
        autoPlay: {
          enabled: true,
          delay: 60000
        }
      },
      carousel: {
        arrowsNav: true,
        arrowsNavAutoHide: false,
        arrowsNavHideOnTouch: true,
        autoHeight: true,
        controlNavigation: 'none',
        controlsInside: false,
        imageScaleMode: 'fill',
        loop: true,
        slidesSpacing: 3
      }
    },

    _init: function _init() {
      const query = document.querySelectorAll.bind(document);

      let rS = query('.royal-slider');

      // stop if no royal-slider present
      if (!rS.length) {
        return false;
      } else {
        if (cCarousel.sConfig !== undefined) {
          cCarousel._getSiteDefaults();
        }
        cCarousel._initSlider(rS);
      }
    },

    _getSiteDefaults: function() {
      for (let key in cCarousel.sliderDefaults) {
        app.utils.extend(cCarousel.sliderDefaults[key], cCarousel.sConfig[key]);
      }
    },

    _getSliderDefaults: function(sliderType) {
      let theDefaults = {};

      switch (sliderType) {
        case 'slider-set':
          return cCarousel.sliderDefaults.sliderSet;

        case 'carousel-set':
          return cCarousel.sliderDefaults.carouselSet;

        case 'product-image-slider':
          return cCarousel.sliderDefaults.productImageSlider;

        case 'product-item-slider':
          return cCarousel.sliderDefaults.productItemSlider;

        default:
          return cCarousel.sliderDefaults.carousel;
      }
    },

    _setItemHeight: function(largestHeight, element) {
      app.element.forEach(function(theElement) {
        theElement.style.height = `${largestHeight}px`;
      }, element);
    },

    _isProductList: function(rS) {
      return !!rS.querySelector('[data-product-item="true"]');
    },

    _getTallestItem: function(element) {
      let rsContent = element.querySelectorAll('.rs-content');

      if (rsContent.length > 0) {

        let largestHeight = 0;
        let heightArr = [];

        app.element.forEach(function(slideContent) {
          heightArr.push(slideContent.offsetHeight);
        }, rsContent);

        largestHeight = Math.max.apply(null, heightArr);
        cCarousel._setItemHeight(largestHeight, rsContent);
      }
    },

    _getSliderType: function(rS) {
      const matches = ['.slider-set', '.carousel-set', '.carousel', '.product-image-slider'].filter(function(selector) {
        return app.element.closestI(selector, rS).element !== document;
      });
      return typeof matches[0] === 'string' ? matches[0].substring(1) : undefined;
    },

    _initSlider: function(rS) {

      for (let i = 0; i < rS.length; i++) {

        if (rS[i].children) {
          rS[i].style.display = 'block';
        }

        if (rS[i].children.length > 1) {

          //if the slider contains product list items force
          let pS = cCarousel._isProductList(rS[i]);
          let sT = pS === true ? 'product-item-slider' : cCarousel._getSliderType(rS[i]);
          let sD = cCarousel._getSliderDefaults(sT);
          cCarousel._getTallestItem(rS[i]);
          $(rS[i]).royalSlider(sD);
        }
      }
    }
  };

  cCarousel._init();

});
